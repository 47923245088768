<template>
  <div class="d-flex flex-column">
    <v-img
      src="https://images.unsplash.com/photo-1547570135-c4d6810a9ada?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
      alt=""
      max-height="300"
      height="300"
    ></v-img>
    <v-container fluid>
      <router-view />
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss"></style>
